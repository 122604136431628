import React from "react"
import Layout from "../components/layout"
import Works from "../components/works"
import Header from "../components/header"



const HomePage = () => {
  return (
    <Layout>
      <Header />
      <section id="work">
        {/* <Works /> */}
      </section> 
    </Layout>
  )
}


export default HomePage

